body {
  margin: 0;
  padding: 0;
  font-family: 'Courier', sans-serif;
  background-color: #dbdbdb; /* Dark gray background */
  color: white;
}

.App {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  max-width: 600px;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.1); /* Light transparent background */
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 3em;
  margin-bottom: 20px;
  color: #282828; /* Lime color */
}

p {
  font-size: 1.5em;
  margin-bottom: 30px;
  color: #444444; /* Orange color */
}

.countdown {
  font-size: 2em;
  font-weight: bold;
  font-family: monospace;
  color: #2a2a2a; /* Lime color for countdown */
  margin-bottom: 20px;
}

span {
  margin: 0 5px;
}

em {
  color: #383939; /* Orange color for Thank you message */
}